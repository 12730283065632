<template>
  <v-card
    flat
    class="rounded-0 fill-height d-flex align-stretch justify-center"
    :class="{ 'flex-column': !mdAndUp }"
  >
    <v-sheet
      min-height="100vh"
      :class="{ fullwidth: !mdAndUp, halfwidth: mdAndUp }"
      class="d-flex px-10"
      color="primary"
    >
      <v-card
        flat
        max-width="450"
        color="transparent"
        class="align-self-center mx-auto fullwidth"
      >
        <v-card-title class="d-flex fullwidth flex-column">
          <v-img
            :lazy-src="settings.loader"
            max-width="300"
            :src="settings.longLogoWhite"
          ></v-img>
          <h2 class="light--text fw-900 fs-25">Forgot Password</h2>
        </v-card-title>
        <v-form v-model="valid">
          <v-card-text>
            <v-row dense>
              <p class="light--text">
                We'll send you an email with further instructions on how to
                reset your password.
              </p>
              <v-col cols="12">
                <label class="light--text fw-700 fs-15">Email</label>
                <v-text-field
                  v-model="email"
                  :rules="requiredEmailRules"
                  dense
                  color="white"
                  filled
                  class=""
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="sent">
                <p class="red--text text-center my-2">
                  A reset instruction is sent to your email!
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex fullwidth flex-column">
            <v-btn
              color="accent"
              elevation="5"
              class="fw-900 rounded-20 py-5 mb-3 light--text ls-unset fs-20"
              :disabled="!valid"
              block
              @click="send"
            >
              Send Instructions
            </v-btn>
            <div class="my-2 light--text">
              <label>
                Want to log in?
                <v-btn
                  link
                  depressed
                  class="text-none light--text"
                  text
                  @click="goSignin"
                >
                  Sign in here
                </v-btn>
              </label>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-sheet>
    <v-sheet
      :min-height="mdAndUp ? `100vh` : ``"
      class="d-flex fill-height fullwidth"
      color="white"
    >
      <v-img
        :lazy-src="settings.loader"
        contain
        :src="require('@/assets/login/gallery-2.png')"
      ></v-img>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    valid: false,
    sent: false,
    message: null,
    email: 'zeus@olympus.site'
  }),
  computed: {
    redirect_uri() {
      return this.$route.query.hasOwnProperty('redirect_uri')
        ? this.$route.query.redirect_uri
        : null
    },
    payload() {
      return {
        email: this.email
      }
    }
  },
  methods: {
    goToSite() {
      window.location = this.settings.wp_site
    },
    goSignin() {
      this.$router.push({
        name: 'login'
      })
    },
    send() {
      this.$auth.forgotPassword(
        this.payload,
        (data) => {
          this.sent = true
          this.message = data.message
        },
        (error) => {
          console.error(error)
        }
      )
    },
    forgotPassword() {
      this.$router.push({
        name: 'forgot-password',
        query: { redirect_uri: this.redirect_uri }
      })
    }
  }
}
</script>

<style></style>
